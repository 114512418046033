import React from 'react'
import IconList from './components/icon'
import './splash.css'

import { AiFillGithub, AiOutlineBehance, AiTwotoneMail} from 'react-icons/ai'


function Splash() {

  return (
    <section className="container flex flex-col md:justify-center sm:h-screen md:-mt-24 mt-10">
      <div className="md:w-3/4">
        <h1 className="text-4xl md:text-6xl font-medium tracking-tight">
          Ola! O meu nome é <span className="underlined">Nuno</span>.
        </h1>
        <h2 className="text-main-600 text-2xl font-medium pt-2">
          UI / UX Designer & Front-End Developer
        </h2>
        <p className="pt-10 lg:w-1/2 text-lg">
          Sou um <strong>UI / UX Designer e Programador Front-End</strong>{' '}
          baseado em <strong>Coimbra</strong> com uma paixão por criar
          experiências únicas online via websites interativos, responsivos e
          acessiveis.
        </p>
        <div className="mt-6">
            <IconList >
                <AiFillGithub  href="https://github.com/nulldays" />
                <AiOutlineBehance href="https://www.behance.net/nfdays" />
                <AiTwotoneMail href="mailto:nfdias1998@gmail.com" />
            </IconList>
        </div>
      </div>
    </section>
  )
}

export default Splash
