function NavList({ children }) {
  return (
    <nav>
      <ul className="list-none">{children}</ul>
    </nav>
  )
}

function NavBtn(props) {
  return (
    <li className="inline-block">
      <a
        className="border-2 border-gray-600 bg-transparent hover:bg-main-600 hover:border-main-600 hover:text-white transition-colors font-bold py-2 px-4 rounded-md text-sm font-main focus:bg-main-700"
        href={props.href}
        download
      >
        {props.text}
      </a>
    </li>
  )
}

function Header() {
  return (
    <header className="container flex flex-row items-center justify-between h-24">
      <h1 className="text-main-500 text-2xl font-medium">Nuno Dias</h1>
      <NavList>
        <NavBtn text="Download CV" href={process.env.PUBLIC_URL + '/cv.pdf'} />
      </NavList>
    </header>
  )
}

export default Header
