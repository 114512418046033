import Header from './components/header'
import Splash from './splash'
//import Resume from './resume'

function App() {
  return (
    <>
      <Header />
      <main>
        <Splash />
      </main>
    </>
  )
}

export default App
