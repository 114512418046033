import React from 'react'

export default function IconList({ children }) {
  const classes =
    'w-10 h-10 mr-3 fill-current hover:text-main-600 transition-colors duration-100'

  const renderChildren = () => {
    return React.Children.map(children, (child, i) => {
      return (
        <li className="inline-block">
          <a href={child.props.href}>
            {React.cloneElement(child, { className: classes })}
          </a>
        </li>
      )
    })
  }

  return <ul className="list-none">{renderChildren()}</ul>
}
/*
 */
